import { useState, useEffect } from 'react';
import { useAuthContext } from '../../Context/AuthContex/AuthContex';
import { Controller, useForm } from 'react-hook-form';
import Toaster from '../Toaster/Toaster';
import { Button, Modal } from 'react-bootstrap';
// import fetcher from '../../Helpers/Fetcher/fetchApi';
import { Multiselect } from 'multiselect-react-dropdown';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/Constant';

export default function LeadsForm({ leads, setLeads, show, handleClose }: any) {
  const { user } = useAuthContext();
  const [buisenessCardFront, setBuisenessCardFront] = useState<any>();
  const [buisenessCardBack, setBuisenessCardBack] = useState<any>();
  const [memberProducts, setMemberProducts] = useState<any>();
  const Toast = Toaster();
  const { register, control, handleSubmit, reset } = useForm();
  let productsId: any[] = [];

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${BaseUrl}/api/fair/get/all/fair-member-product/by/${user.user_fair_member_id}/approved`
      );
      setMemberProducts(data?.data);
    })();
  }, [user.user_fair_member_id]);

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append('name', data?.name);
    formData.append('email', data?.email);
    formData.append('phone', data?.phone);
    formData.append('lead_remarks', data?.lead_remarks);
    formData.append('business_card_front', buisenessCardFront);
    formData.append('business_card_back', buisenessCardBack);

    productsId = memberProducts
      ?.map((product: any) => {
        if (
          data?.products?.includes(product.fair_member_product_name) === true
        ) {
          return product.fair_member_product_id;
        }
      })
      .filter((item: any) => item !== undefined);

    if (!productsId.length) {
      Toast.fire({
        icon: 'error',
        title: `Please Select Product`,
      });
      return;
    }

    formData.append('products', JSON.stringify(productsId));

    const { data: response } = await axios.post(
      `${BaseUrl}/api/fair/lead/add-wishlist`,
      formData
    );

    if (response.success) {
      Toast.fire({
        icon: 'success',
        title: `Successfully created`,
      });
      setLeads([
        ...leads,
        { name: data.name, organization: data.organization, phone: data.phone },
      ]);
    }
    reset();
    handleClose();
  };

  return (
    <Modal
      // fullscreen="xl-down"
      size='lg'
      show={show}
      onHide={handleClose}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='register-top-form-card'>
            <div className='row g-3'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div>
                  <label>
                    Name <span className='require'>*</span>
                  </label>
                  <div className='w-150-100'>
                    <input
                      type='text'
                      required
                      className='form-control '
                      {...register('name')}
                      placeholder='Name'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>
                    Phone <span className='require'>*</span>
                  </label>
                  <div className='w-150-100'>
                    <input
                      required
                      type='number'
                      maxLength={11}
                      {...register('phone')}
                      className='form-control '
                      placeholder='Phone'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Email</label>
                  <div className='w-150-100'>
                    <input
                      required
                      type='email'
                      {...register('email')}
                      className='form-control'
                      placeholder='Email'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Select product</label>
                  <Controller
                    control={control}
                    name='products'
                    render={({ field: { value, onChange } }) => (
                      <Multiselect
                        options={memberProducts?.map(
                          (item: any) => item?.fair_member_product_name
                        )}
                        isObject={false}
                        showCheckbox={true}
                        hidePlaceholder={true}
                        closeOnSelect={false}
                        onSelect={onChange}
                        onRemove={onChange}
                        selectedValues={value}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Remark</label>
                  <div className='w-100'>
                    <textarea
                      {...register('lead_remarks')}
                      cols={40}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6'>
                <div className='companies-logo-card'>
                  <div className='img-thumbnail ' style={{ width: '280px' }}>
                    {buisenessCardFront ? (
                      <img
                        style={{
                          width: '200px',
                          height: '100px',
                        }}
                        className=' ls-is-cached lazyloaded'
                        id='uploadedImage'
                        src={URL.createObjectURL(buisenessCardFront)}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{
                          width: '200px',
                          height: '100px',
                          objectFit: 'contain',
                        }}
                        className=' ls-is-cached lazyloaded'
                        id='uploadedImage'
                        src='/assets/image.png'
                        alt=''
                      />
                    )}
                  </div>
                  <div className='pt-2'>
                    <input
                      type='file'
                      id='actual-btn1'
                      accept='image/jpg, image/png, image/jpeg'
                      hidden
                      onChange={(e: any) =>
                        setBuisenessCardFront(e.target.files[0])
                      }
                    />

                    <label
                      htmlFor='actual-btn1'
                      className='upload-btn btn-images-upload'
                    >
                      {' '}
                      Buiseness card (Front)
                    </label>
                  </div>
                </div>
                <div className='form-text'>
                  For Best View Upload Image size 600 X 600px
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6'>
                <div className=''>
                  <div className='companies-logo-card'>
                    <div className='img-thumbnail ' style={{ width: '280px' }}>
                      {buisenessCardBack ? (
                        <img
                          style={{
                            width: '200px',
                            height: '100px',
                          }}
                          className=' ls-is-cached lazyloaded'
                          id='uploadedImage'
                          src={URL.createObjectURL(buisenessCardBack)}
                          alt=''
                        />
                      ) : (
                        <img
                          style={{
                            width: '200px',
                            height: '100px',
                            objectFit: 'contain',
                          }}
                          className=' ls-is-cached lazyloaded'
                          id='uploadedImage'
                          src='/assets/image.png'
                          alt=''
                        />
                      )}
                    </div>
                    <div className='pt-2'>
                      <input
                        type='file'
                        id='actual-btn2'
                        accept='image/jpg, image/png, image/jpeg'
                        hidden
                        onChange={(e: any) =>
                          setBuisenessCardBack(e.target.files[0])
                        }
                      />

                      <label
                        htmlFor='actual-btn2'
                        className='upload-btn btn-images-upload'
                      >
                        {' '}
                        Buiseness card (Back)
                      </label>
                    </div>
                  </div>
                  <div className='form-text'>
                    For Best View Upload Image size 600 X 600px
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-3 ms-auto order-2'>
              <div className='ms-auto order-2'>
                <Button type='submit' className='w-100 mt-5  submit-button'>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
