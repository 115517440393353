import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MART from '../../img/mart.jpg';
const Footer = () => {
  return (
    <>
      <div className=' footer-container my-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <h1
              className='text-center m-auto text-danger'
              style={{ fontWeight: 300 }}
            >
              Dhaka Travel Mart
            </h1>
            <p className='text-center m-auto mt-2'>WHO MAKES THIS POSSIBLE!</p>
            <div className='col-lg-24 col-md-12 my-5     text-center'>
              <img
                style={{ alignItems: 'center', justifyContent: 'center' }}
                className='img-fluid text-center'
                src={MART}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
      <div className='meeta-footer-section meeta-footer-3 '>
        <div className='footer-wrap'>
          <div className='container'>
            <div className='footer-widget-wrap'>
              <Row>
                <Col className='md-ps-5' xs={12} md={3}>
                  <h6 className='text-warning'>FAIR ESSENTIALS</h6>
                  <ul>
                    <li>
                      <a href='/'>Fair catalogue</a>
                    </li>
                    <li>
                      <a href='/'>Exibation Layout</a>
                    </li>
                    <li>
                      <a href='/'>Participation Rate</a>
                    </li>
                    <li>
                      <a href='/'>Booth Registration</a>
                    </li>
                    <li>
                      <a href='/'>Logistic Support</a>
                    </li>
                  </ul>
                </Col>
                <Col className='md-ps-5' xs={12} md={3}>
                  <h6 className='text-warning'>TRAVEL ESSENTIALS</h6>
                  <ul>
                    <li>
                      <a href='/'>About Bangladesh</a>
                    </li>
                    <li>
                      <a href='/'>Visa Information</a>
                    </li>
                    <li>
                      <a href='/'>BD Ambassy in Abroad</a>
                    </li>
                    <li>
                      <a href='/'>Official Hotels</a>
                    </li>
                    <li>
                      <a href='/'>Post Fair Tour</a>
                    </li>
                    <li>
                      <a href='/'>Local Transport</a>
                    </li>
                  </ul>
                </Col>
                {/* <Col className='md-ps-5' xs={12} md={3}>
                  <h6 className='text-warning'>NEWS</h6>
                  <ul>
                    <li>
                      <h2 className='text-white'>Recent</h2>
                    </li>
                    <ul>
                      <li>
                        {' '}
                        
                      </li>
                    </ul>
                  </ul>
                </Col> */}
              </Row>
            </div>
          </div>
          <div
            className='social-links'
            style={{ backgroundColor: '#16A34A', color: 'black' }}
          >
            <p className='text-center mx-auto'>
              ©Copyright 2023 – Dhaka Travel Mart. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
