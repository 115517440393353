import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import LeadsForm from "./LeadsForm";
import { useAuthContext } from "../../Context/AuthContex/AuthContex";
import LeadsProducts from "./LeadsProducts";
import moment from "moment";
import axios from "axios";
import { BaseUrl } from "../../Helpers/Constant";
import { AiFillEye } from "react-icons/ai";
const MyLeads = () => {
  const [leads, setLeads] = useState<any>();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [singleLead, setSingleLead] = useState<any>({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showProduct, setShowProduct] = useState(false);
  const handleCloseProduct = () => setShowProduct(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      const { data } = await axios.get(
        `${BaseUrl}/api/fair/lead/get/lead-by/${user?.user_fair_member_id}`
      );

      if (data.success) {
        setLeads(data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [user?.user_fair_member_id]);

  return (
    <>
      <div className="profile-right-side  py-3">
        <div>
          <div className="d-flex justify-content-between p-2">
            <h5>My Leads (Exhibition)</h5>
            <Button variant="primary" onClick={handleShow}>
              Create Lead
            </Button>
          </div>
          <LeadsForm
            leads={leads}
            setLeads={setLeads}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            setShow={setShow}
          />
        </div>
        <div className="profile-right-info pt-3 ps-2 text-center">
          {leads?.length ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Created At</th>
                  <th> Action</th>
                </tr>
              </thead>
              {leads?.length &&
                leads?.map((lead: any) => {
                  return (
                    <tbody key={lead.lead_id}>
                      <tr>
                        <td>{lead.name}</td>
                        <td>{lead.phone}</td>
                        <td>
                          {moment(lead?.lead_created_at).format("MMM Do YY")}{" "}
                        </td>
                        <td
                          className="text-right"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowProduct(true);
                            setSingleLead(lead);
                          }}
                        >
                          <span>
                            <AiFillEye />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </Table>
          ) : (
            <span className="mx-auto text-center my-3 text-danger">
              You have no leads
            </span>
          )}
        </div>
      </div>

      <LeadsProducts
        singleLead={singleLead}
        loading={loading}
        handleCloseProduct={handleCloseProduct}
        handleShowProduct={showProduct}
        setShowProduct={setShowProduct}
        showProduct={showProduct}
      />
    </>
  );
};

export default MyLeads;
