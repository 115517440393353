import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Toaster from '../Toaster/Toaster';
import { Button } from 'react-bootstrap';
// import fetcher from '../../Helpers/Fetcher/fetchApi';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/Constant';

export default function CreateProfile() {
  const [buisenessCardFront, setBuisenessCardFront] = useState<any>();
  const [buisenessCardBack, setBuisenessCardBack] = useState<any>();
  const Toast = Toaster();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    const formData = new FormData();

    formData.append('name', data?.name);
    formData.append('email', data?.email);
    formData.append('phone', data?.phone);
    formData.append('lead_remarks', data?.lead_remarks);
    formData.append('products', data?.products);
    formData.append('business_card_front', buisenessCardFront);
    formData.append('business_card_back', buisenessCardBack);
    const { data: response } = await axios.post(
      `${BaseUrl}/api/fair/lead/add-wishlist`,
      formData
    );
    if (response.success) {
      Toast.fire({
        icon: 'success',
        title: `Successfully created`,
      });
    }
    navigate('/profile/my-leads');
  };

  return (
    <div className='container  pt-5'>
      <div className='register-top'>
        <div className='register-top-div'>
          <h3>Lead</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='register-top-form-card'>
            <div className='row g-3'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div>
                  <label>
                    Name <span className='require'>*</span>
                  </label>
                  <div className='w-150-100'>
                    <input
                      type='text'
                      required
                      className='form-control '
                      {...register('name')}
                      placeholder='Name'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>
                    Phone <span className='require'>*</span>
                  </label>
                  <div className='w-150-100'>
                    <input
                      required
                      type='number'
                      minLength={11}
                      {...register('phone')}
                      className='form-control '
                      placeholder='Phone'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Email</label>
                  <div className='w-150-100'>
                    <input
                      required
                      type='email'
                      {...register('email')}
                      className='form-control'
                      placeholder='Email'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Product</label>
                  <div className='w-150-100'>
                    <input
                      required
                      type='text'
                      {...register('products')}
                      className='form-control col-sm-2 custom_input_field '
                      placeholder='product name'
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Remark</label>
                  <div className='w-100'>
                    <textarea
                      {...register('lead_remarks')}
                      cols={50}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <label>Organization</label>
                  <div className='w-150-100'>
                    <input
                      required
                      type='text'
                      {...register('user_fair_member_contact_number')}
                      className='form-control col-sm-2 custom_input_field '
                      placeholder='Organization'
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <div className='w-150-100'>
                    <div className='companies-logo-card'>
                      <div
                        className='img-thumbnail '
                        style={{ width: '280px' }}
                      >
                        {buisenessCardFront ? (
                          <img
                            style={{
                              width: '270px',
                              height: '135px',
                            }}
                            className=' ls-is-cached lazyloaded'
                            id='uploadedImage'
                            src={URL.createObjectURL(buisenessCardFront)}
                            alt=''
                          />
                        ) : (
                          <img
                            style={{
                              width: '270px',
                              height: '135px',
                              objectFit: 'contain',
                            }}
                            className=' ls-is-cached lazyloaded'
                            id='uploadedImage'
                            src='/assets/image.png'
                            alt=''
                          />
                        )}
                      </div>
                      <div className='pt-2'>
                        <input
                          type='file'
                          id='actual-btn1'
                          accept='image/jpg, image/png, image/jpeg'
                          hidden
                          onChange={(e: any) =>
                            setBuisenessCardFront(e.target.files[0])
                          }
                        />

                        <label
                          htmlFor='actual-btn1'
                          className='upload-btn btn-images-upload'
                        >
                          {' '}
                          Buiseness card (Front)
                        </label>
                      </div>
                    </div>
                    <div className='form-text'>
                      For Best View Upload Image size 600 X 600px
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                <div className='row'>
                  <div className='w-150-100'>
                    <div className='companies-logo-card'>
                      <div
                        className='img-thumbnail '
                        style={{ width: '280px' }}
                      >
                        {buisenessCardBack ? (
                          <img
                            style={{
                              width: '270px',
                              height: '135px',
                            }}
                            className=' ls-is-cached lazyloaded'
                            id='uploadedImage'
                            src={URL.createObjectURL(buisenessCardBack)}
                            alt=''
                          />
                        ) : (
                          <img
                            style={{
                              width: '270px',
                              height: '135px',
                              objectFit: 'contain',
                            }}
                            className=' ls-is-cached lazyloaded'
                            id='uploadedImage'
                            src='/assets/image.png'
                            alt=''
                          />
                        )}
                      </div>
                      <div className='pt-2'>
                        <input
                          type='file'
                          id='actual-btn2'
                          accept='image/jpg, image/png, image/jpeg'
                          hidden
                          onChange={(e: any) =>
                            setBuisenessCardBack(e.target.files[0])
                          }
                        />

                        <label
                          htmlFor='actual-btn2'
                          className='upload-btn btn-images-upload'
                        >
                          {' '}
                          Buiseness card (Back)
                        </label>
                      </div>
                    </div>
                    <div className='form-text'>
                      For Best View Upload Image size 600 X 600px
                    </div>
                  </div>
                  <div className='col-3 col-sm-4 col-md-2 col-lg-3 col-xl-3 ms-auto order-2'>
                    <Button type='submit' className='w-100 mt-5 submit-button '>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
