import { createBrowserRouter } from 'react-router-dom';
// import Home from "../Pages/Home/Home";
import Login from '../Pages/Login/Login';
// import Profile from "../Pages/Profile/Profile";
// import ProfileHomepage from "../Components/Profile/ProfileHomepage";
// import SpaceRequirements from "../Components/Profile/SpaceRequirements";
// import ChangePassword from "../Components/Profile/ChangePassword";
// import PrivateRoute from "../Components/PrivateRoute/PrivateRoute";
import ForgetPassword from '../Pages/ForgetPassword/ForgetPassword';
import MatchOtp from '../Components/MatchOtp/MatchOtp';
import NewPassword from '../Components/NewPassword/NewPassword';
import BookYourSpace from '../Pages/BookYourSpace/BookYourSpace';
import BuyerRegister from '../Pages/BuyerRegister/BuyerRegister';
import VisitorLogin from '../Pages/VisitorLogin/VisitorLogin';
import VisitorRegistration from '../Pages/VisitorRegistration/VisitorRegistration';
// import ProductAndServices from "../Components/ProductAndServices/ProductAndServices";
// import OnlineVisitors from "../Components/OnlineVisitors/OnlineVisitors";
// import RegisterVisitors from "../Components/RegisterVisitors/RegisterVisitors";
import SeminarParticipantLogin from '../Pages/SeminarParticipantLogin/SeminarParticipantLogin';
import VisitorProfile from '../Pages/VisitorProfile/VisitorProfile';
import VisitorProfileHomePage from '../Components/Visitor/VisitorProfileHomePage/VisitorProfileHomePage';
import VisitorPrivateRoute from '../Components/PrivateRoute/VisitorPrivateRoute';
import VisitorWishlist from '../Pages/VisitorWishlist/VisitorWishlist';
import NotFound from '../Pages/NotFound/NotFound';
// import MyLeads from "../Components/MyLeads/MyLeads";
import VisitorInvoice from '../Pages/VisitorInvoice/VisitorInvoice';
import VisitorForgetPassword from '../Pages/VisitorForgetPassword/VisitorForgetPassword';
import VisitorMatchOtp from '../Pages/VisitorMatchOtp/VisitorMatchOtp';
import VisitorNewPassword from '../Pages/VisitorNewPassword/VisitorNewPassword';
import VisitorChangePassword from '../Pages/VisitorChangePassword/VisitorChangePassword';
import RegisteredEvents from '../Pages/RegisteredEvents/RegisteredEvents';
import EventList from '../Pages/EventList/EventList';
import SingleEvent from '../Pages/SingleEvent/SingleEvent';
import RegisterSpeaker from '../Components/RegisterSpeaker/RegisterSpeaker';
import SpeakerProfile from '../Components/Speaker/SpeakerProfile';
import SpeakerPrivateRoute from '../Components/PrivateRoute/SpeakerPrivateRoute';
import SpeakerLogin from '../Pages/SpeakerLogin/SpeakerLogin';
import CreateProfile from '../Components/MyLeads/CreateProfile';
import VisitorProductAndService from '../Components/VisitorProductAndService';
import VisitorEventList from '../Pages/VisitorEventList/VisitorEventList';
import VisitorSingleEventList from '../Pages/VisitorEventList/VisitorSingleEventList';
import ExhibitorList from '../Pages/ExhibitorList/ExhibitorList';
import SeminarList from '../Pages/SeminarList/SeminarList';
import SeminarEventList from '../Pages/SeminarEventList/SeminarEventList';
import RaffleDraw from '../Pages/RaffleDraw/RaffleDraw';
import Home from '../Pages/Home/Home';
import ProfileHomepage from '../Components/Profile/ProfileHomepage';
import Profile from '../Pages/Profile/Profile';
import PrivateRoute from '../Components/PrivateRoute/PrivateRoute';
import SpaceRequirements from '../Components/RegistrationComponents/SpaceRequirments';
import ProductAndServices from '../Components/ProductAndServices/ProductAndServices';
import MyLeads from '../Components/MyLeads/MyLeads';
import OnlineVisitors from '../Components/OnlineVisitors/OnlineVisitors';
import RegisterVisitors from '../Components/RegisterVisitors/RegisterVisitors';
import ChangePassword from '../Components/Profile/ChangePassword';

export const routers = createBrowserRouter([
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/',
    // element: <VisitorLogin />,
    element: <Home />,
  },
  {
    path: '/home',
    // element: <VisitorLogin />,
    element: <Home />,
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'book-your-space',
    element: <BookYourSpace />,
  },
  {
    path: 'buyer-register',
    element: <BuyerRegister />,
  },
  {
    path: 'create-lead',
    element: <CreateProfile />,
  },
  {
    path: 'speaker-registration',
    element: <RegisterSpeaker />,
  },
  {
    path: 'visitor-login',
    element: <VisitorLogin />,
  },
  {
    path: 'visitor-registration',
    element: <VisitorRegistration />,
  },

  {
    path: 'seminar-participant-login',
    element: <SeminarParticipantLogin />,
  },

  {
    path: 'profile',
    element: <PrivateRoute component={<Profile />} />,
    children: [
      {
        path: '/profile',
        element: <ProfileHomepage />,
      },
      {
        path: 'space-requirements',
        element: <SpaceRequirements />,
      },
      {
        path: 'product-and-services',
        element: <ProductAndServices />,
      },
      {
        path: 'my-leads',
        element: <MyLeads />,
      },
      {
        path: 'online-visitors',
        element: <OnlineVisitors />,
      },
      {
        path: 'register-visitors',
        element: <RegisterVisitors />,
      },
      {
        path: 'change-password',
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: 'speaker',
    element: <SpeakerPrivateRoute component={<SpeakerProfile />} />,
  },
  {
    path: 'speaker-login',
    element: <SpeakerLogin />,
  },
  {
    path: 'event-list',
    element: <SpeakerPrivateRoute component={<EventList />} />,
  },
  {
    path: 'event-list/:id',
    element: <SpeakerPrivateRoute component={<SingleEvent />} />,
  },
  // {
  //   path: '/product-and-service',
  //   element: <VisitorPrivateRoute component={<VisitorProductAndService />} />
  // },
  {
    path: '/exhibitor-list',
    element: <VisitorPrivateRoute component={<ExhibitorList />} />,
  },
  {
    path: '/seminar-list',
    element: <SeminarList />,
  },
  {
    path: '/seminar-event-list/:id',
    element: <SeminarEventList />,
  },
  // {
  // 	path: '/visitor-event-list/:id',
  // 	element: <VisitorSingleEventList />,
  // },
  {
    path: '/raffle-draw',
    element: <RaffleDraw />,
  },

  {
    path: '/exhibitor-list/:id',
    element: <VisitorPrivateRoute component={<VisitorProductAndService />} />,
  },
  {
    path: 'visitor-event-list',
    element: <VisitorEventList />,
  },
  {
    path: '/visitor-event-list/:id',
    element: <VisitorSingleEventList />,
  },

  {
    path: 'visitor',
    element: <VisitorPrivateRoute component={<VisitorProfile />} />,

    children: [
      {
        path: 'profile',
        element: <VisitorProfileHomePage />,
      },
      {
        path: 'register-event',
        element: <RegisteredEvents />,
      },
      {
        path: 'invoice',
        element: <VisitorInvoice />,
      },
      {
        path: 'wishlist',
        element: <VisitorWishlist />,
      },
      {
        path: 'change-password',
        element: <VisitorChangePassword />,
      },
      {
        path: 'change-password',
        element: <VisitorProductAndService />,
      },
    ],
  },
  {
    path: 'forget-password',
    element: <ForgetPassword />,
  },
  {
    path: 'match-otp',
    element: <MatchOtp />,
  },
  {
    path: 'new-password',
    element: <NewPassword />,
  },
  {
    path: 'visitor-forget-password',
    element: <VisitorForgetPassword />,
  },
  {
    path: 'visitor-match-otp',
    element: <VisitorMatchOtp />,
  },
  {
    path: 'visitor-new-password',
    element: <VisitorNewPassword />,
  },
]);
