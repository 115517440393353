import { Modal, Table } from "react-bootstrap";

export default function LeadsProducts({
  singleLead,
  handleCloseProduct,
  showProduct,
}: any) {
  return (
    <>
      <Modal size="lg" show={showProduct} onHide={handleCloseProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Single lead product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ps-1 mb-2">
            <ul>
              <li className="d-flex align-items-center gap-2">
                <span>Name</span> : <p>{singleLead?.name}</p>
              </li>

              <li className="d-flex align-items-center gap-2">
                <span>Email</span> : <p>{singleLead?.email}</p>
              </li>
              {singleLead?.phone && (
                <li className="d-flex align-items-center gap-2">
                  <span>Phone</span> : <p>{singleLead?.phone}</p>
                </li>
              )}
              {singleLead?.organization && (
                <li className="d-flex align-items-center gap-2">
                  <span>Organization</span> : <p>{singleLead?.organization}</p>
                </li>
              )}
            </ul>
          </div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Product name</th>
              </tr>
            </thead>
            <tbody>
              {singleLead?.products?.length &&
                singleLead?.products?.map((product: any) => (
                  <tr>
                    <td>{product.fair_member_product_id}</td>
                    <td>{product.fair_member_product_name}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
