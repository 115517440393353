import { useEffect, useState } from 'react';
import { IEvent } from '../../Types/EventsType';
import Layout from '../../Components/Layout/Layout';
import { Col, Container, InputGroup, Row } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { BiTimeFive } from 'react-icons/bi';
import moment from 'moment';
import { BaseUrl, imgUrl } from '../../Helpers/Constant';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';

export default function VisitorEventList() {
  const [allEvents, setAllEvents] = useState<IEvent[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setLoading(true);
      let url = `/api/fair/event/get/all/all`;
      if (searchValue) {
        url = `/api/fair/event/get/${searchValue}/all`;
      }
      (async () => {
        const { data } = await axios.get(`${BaseUrl}${url}`);

        if (data.success) {
          setAllEvents(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [searchValue]);

  return (
    <>
      {loading && <Loader />}
      {loading === false && allEvents?.length === 0 && (
        <h2 className='text-danger text-center'>Events not found</h2>
      )}
      {
        <Layout>
          <hr />
          <Container>
            <div className='section-title breadcrumb-card d-flex flex-mo-column align-items-center  justify-content-between mt-5'>
              <div className='text-start w-50'>
                <h2 className='mb-0 my-3'>Dhaka Travel Mart Events</h2>
              </div>
              <div className='w-50 d-flex flex-mo-column justify-content-end'>
                <div className='filter-parent'>
                  <InputGroup>
                    <InputGroup.Text id='basic-addon1'>
                      <AiOutlineSearch />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder='Search Event'
                      aria-label='Username'
                      onChange={(e) => setSearchValue(e.target.value)}
                      aria-describedby='basic-addon1'
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <>
              {/* {(allEvents?.length === 0) && <h2 className='text-center text-danger fs-4 fw-bold'>No event  found</h2>} */}
              {
                <>
                  <Row sx={12} md={4}>
                    {allEvents?.length &&
                      allEvents?.map((sEvent) => {
                        return (
                          <Col key={sEvent?.event_id}>
                            <div className='events-card-bg event_container mb-4'>
                              <div className='events-img-sec'>
                                <Link
                                  to={`/visitor-event-list/${sEvent?.event_id}`}
                                >
                                  <div className='event_wrapper'>
                                    <div className='event_venue '>
                                      <div>
                                        <BiTimeFive color='white' />
                                        <span>
                                          {moment(
                                            sEvent?.event_start_time,
                                            'HH:mm:ss'
                                          ).format('hh:mm A')}{' '}
                                          {sEvent?.event_end_time && (
                                            <>
                                              - {''}
                                              {moment(
                                                sEvent?.event_end_time,
                                                'HH:mm:ss'
                                              ).format('hh:mm A')}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <img
                                      className='event-img'
                                      style={{
                                        minHeight: '14rem',
                                        maxHeight: '14rem',
                                      }}
                                      alt=''
                                      src={`${imgUrl}asian_tourism_fair/event_files/${sEvent?.event_photo}`}
                                    />
                                  </div>
                                </Link>
                              </div>
                              <div className='text-center'>
                                <h3 className='event_title'>
                                  <Link
                                    to={`/visitor-event-list/${sEvent?.event_id}`}
                                    className='text-decoration-none text-black'
                                  >
                                    {sEvent?.event_title?.slice(0, 50)}...
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </>
              }
            </>
          </Container>
        </Layout>
      }
    </>
  );
}
