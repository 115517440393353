import { useState, useEffect } from 'react';
import { IStalls } from '../../Types/RegisterTypes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/Constant';

const SpaceRequirements = ({ setStalls, stalls }: any) => {
  const [allStalls, setAllStalls] = useState<IStalls[]>([]);
  const [status, setStatus] = useState('');

  // image modal
  const [showImage, setShowImage] = useState(false);

  // modal state
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${BaseUrl}/api/fair/stall/get/all/status/hall-name/all/all`
      );
      if (data.success) {
        setAllStalls(data.data);
      }
    })();
  }, []);

  return (
    <>
      <div className='register-top'>
        <div className='register-top-div d-flex align-items-center justify-content-between'>
          <h3>Space Requirements </h3>
          <h5 onClick={() => setShowImage(true)} className='pointer'>
            Venue layout
          </h5>
        </div>
        <div className='register-top-form-card  space-requirmetn-div'>
          <Row xxl={6} xs={3} md={5}>
            {allStalls?.length &&
              allStalls.map((stall) => {
                return (
                  <Col key={stall.fair_stall_id}>
                    <div
                      onClick={() => {
                        const newStatusStall = allStalls.filter(
                          (s) => s.fair_stall_id === stall.fair_stall_id
                        );

                        if (stall.fair_stall_available_status === 'available') {
                          setStalls([...stalls, stall.fair_stall_id]);
                          newStatusStall[0].fair_stall_available_status =
                            'selected';
                        } else if (
                          newStatusStall[0].fair_stall_available_status ===
                          'selected'
                        ) {
                          newStatusStall[0].fair_stall_available_status =
                            'available';
                          const newStall = stalls.filter(
                            (st: any) => st !== stall.fair_stall_id
                          );
                          setStalls(newStall);
                        } else {
                          handleShow();
                          setStatus(stall.fair_stall_available_status);
                        }
                      }}
                      className={
                        stall.fair_stall_available_status === 'selected'
                          ? 'select-item'
                          : stall.fair_stall_available_status === 'available'
                          ? 'select__item'
                          : stall.fair_stall_available_status === 'reserved'
                          ? 'selected-item'
                          : 'unavailable-item'
                      }
                    >
                      <p>{stall.fair_stall_name}</p>

                      <span style={{ fontSize: '13px' }}>
                        Hall No: {stall.fair_stall_hall_no}
                      </span>
                      <br />
                      <span style={{ fontSize: '13px' }}>
                        {stall.fair_stall_available_status}
                      </span>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <p className='py-5 text-center fs-2'>
          This stall is <span className='text-secondary fw-bold'>{status}</span>{' '}
        </p>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* venu layout modal  */}
      <Modal show={showImage} fullscreen onHide={() => setShowImage(false)}>
        <div className='text-end pe-3 pt-3 pointer'>
          <AiOutlineClose size={30} onClick={() => setShowImage(false)} />
        </div>

        <Modal.Body className='text-center'>
          <h2>Venue Layout will be shown</h2>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SpaceRequirements;
