import { useState, useEffect } from 'react';
import { ISpeaker } from '../../Types/VisitorAllTypes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BaseUrl, imgUrl } from '../../Helpers/Constant';
import axios from 'axios';

const SingleEventSpeaker = ({ id }: any) => {
  const [speakers, setSpeakers] = useState<ISpeaker[]>([]);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${BaseUrl}/api/fair/event/get/all/speaker/by/${id}`
      );
      if (data.success) {
        setSpeakers(data.data);
      }
    })();
  }, []);

  return (
    <div>
      {speakers?.length ? (
        <Row xs={12} md={4}>
          {speakers?.map((speaker) => {
            return (
              <Col>
                <div className='single-speakers-box'>
                  <div className='speakers-image'>
                    <img
                      className='w-100'
                      src={`${imgUrl}asian_tourism_fair/guest-speaker-files/${speaker?.guest_speaker_photo}`}
                      alt=''
                    />
                  </div>

                  <div className='speakers-content'>
                    <div className='top-content text-center'>
                      <h5>{speaker.guest_speaker_name}</h5>
                      <div>
                        <p className='pb-2'>
                          {speaker.guest_speaker_designation}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        <h4 className='text-danger fw-bold text-center my-5'>
          Speaker Not Found!
        </h4>
      )}
    </div>
  );
};

export default SingleEventSpeaker;
