import { useState } from 'react';
import HeaderBottom from '../HeaderBottom/HeaderBottom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Toaster from '../Toaster/Toaster';
import { ISpeaker } from '../../Types/VisitorAuthTypes';
// import fetcher from '../../Helpers/Fetcher/fetchApi';
import {
  AUTH_SPEAKER_USER_FAILED,
  AUTH_SPEAKER_USER_SUCCESS,
  BaseUrl,
} from '../../Helpers/Constant';
import { setCookie } from 'nookies';
import { SpeakerAuthContext } from '../../Context/AuthContex/SpeakerAuthContext';
import axios from 'axios';

export default function RegisterSpeaker() {
  const Toast = Toaster();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState<string>('password');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [profilePhoto, setProfilePhoto] = useState<any>('');
  const { register, handleSubmit, reset } = useForm<ISpeaker>();
  const { dispatch: authDispatch, setIsLoading } = SpeakerAuthContext();

  const onSubmit: SubmitHandler<ISpeaker> = async (data: any) => {
    if (!profilePhoto) {
      Toast.fire({
        icon: 'error',
        title: `Upload speaker profile photo `,
      });
      setIsLoading?.(false);
      return;
    }
    if (data.guest_speaker_password !== confirmPassword) {
      Toast.fire({
        icon: 'error',
        title: `Password does not match `,
      });
      setIsLoading?.(false);
      return;
    }
    if (data.guest_speaker_password.length < 8) {
      Toast.fire({
        icon: 'error',
        title: `Password must be at least 8 characters`,
      });
      setIsLoading?.(false);
      return;
    }

    /// form data

    const fromData = new FormData();
    Object.keys(data).forEach((item) => {
      fromData.append(item, data[item]);
    });
    fromData.append('guest_speaker_photo', profilePhoto);

    console.table(Object.fromEntries(fromData));

    try {
      const { data: res } = await axios.post(
        `${BaseUrl}/api/auth/speaker/register`,
        fromData
      );

      if (res.success) {
        Toast.fire({
          icon: 'success',
          title: `successfully register`,
        });
        authDispatch?.({
          type: AUTH_SPEAKER_USER_SUCCESS,
          payload: res.data,
        });

        setCookie(null, 'token', res.token);
        localStorage.setItem('user', JSON.stringify(res.data));
        localStorage.setItem('token', res.token);
        navigate('/speaker');
      } else {
        Toast.fire({
          icon: 'error',
          title: res.message,
        });
        setIsLoading?.(false);
        authDispatch?.({ type: AUTH_SPEAKER_USER_FAILED });
      }
    } catch (error) {
      authDispatch?.({ type: AUTH_SPEAKER_USER_FAILED });
    }
    reset();
  };
  return (
    <div>
      <Header />
      <HeaderBottom pathName={'Speaker Registration'} />
      <div className='login-wrapper'>
        <div className='login-div'>
          <div className='visitor-registration-page'>
            <div className='text-center mt-3'>
              <p className='fw-bold fs-3'>Speaker Registration</p>
            </div>

            <div className='login-form'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row g-3 mx'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                    <div>
                      <label className='col-form-label'>
                        Full Name <span className='require'>*</span>
                      </label>
                      <div>
                        <input
                          type='text'
                          {...register('guest_speaker_name')}
                          className='form-control'
                          placeholder='Enter full name'
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                    <div className='row  align-items-center'>
                      <div className=' gap-3'>
                        <label className='col-form-label'>
                          Guest Type
                          <span className='require'>*</span>
                        </label>

                        <div>
                          <select
                            {...register('guest_speaker_type')}
                            required
                            className='form-control'
                          >
                            <option value='speaker'>Speaker</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div>
                      <label className='col-form-label'>
                        Email <span className='require'>*</span>
                      </label>
                      <div>
                        <input
                          type='email'
                          required
                          {...register('guest_speaker_email')}
                          className='form-control valid'
                          placeholder='Enter email address'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div>
                      <label className='col-form-label'>
                        Company Name <span className='require'>*</span>
                      </label>
                      <div>
                        <div className='input-group'>
                          <input
                            type='text'
                            required
                            {...register('guest_speaker_company_name')}
                            className='mobile-form form-control '
                            placeholder='Enter company name'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div>
                      <label className='col-form-label'>
                        Designation <span className='require'>*</span>
                      </label>
                      <div>
                        <div className='input-group'>
                          <input
                            type='text'
                            required
                            {...register('guest_speaker_designation')}
                            className='mobile-form form-control '
                            placeholder='Enter designation'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                    <div className='row d-flex'>
                      <div>
                        <label>Profile Photo</label>
                        <div className='w-150-100'>
                          <div className='companies-logo-card'>
                            <div
                              className='img-thumbnail '
                              style={{ width: '280px' }}
                            >
                              {profilePhoto ? (
                                <img
                                  style={{
                                    width: '270px',
                                    height: '135px',
                                  }}
                                  className=' ls-is-cached lazyloaded'
                                  id='uploadedImage'
                                  src={URL.createObjectURL(profilePhoto)}
                                  alt=''
                                />
                              ) : (
                                <img
                                  style={{
                                    width: '270px',
                                    height: '135px',
                                    objectFit: 'contain',
                                  }}
                                  className=' ls-is-cached lazyloaded'
                                  id='uploadedImage'
                                  src='/assets/image.png'
                                  alt=''
                                />
                              )}
                            </div>
                            <div>
                              <input
                                type='file'
                                id='actual-btn1'
                                accept='image/jpg, image/png, image/jpeg'
                                hidden
                                onChange={(e: any) =>
                                  setProfilePhoto(e.target.files[0])
                                }
                              />

                              <label
                                htmlFor='actual-btn1'
                                className='upload-visitor-image-btn text-center'
                              >
                                {' '}
                                Upload photo
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className='col-form-label'>
                          Material Details <span className='require'>*</span>
                        </label>
                        <div>
                          <textarea
                            required
                            {...register('guest_speaker_details')}
                            className='form-control'
                            placeholder='Enter speaker details'
                            cols={20}
                            rows={5}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                    
                  </div> */}

                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                    <div>
                      <label className='col-form-label'>
                        Password <span className='require'>*</span>
                      </label>
                      <div>
                        <input
                          required
                          {...register('guest_speaker_password')}
                          type={passwordType}
                          className='form-control valid'
                          placeholder='Enter password'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                    <div>
                      <label className='col-form-label'>
                        Confirm Password <span className='require'>*</span>
                      </label>
                      <div>
                        <input
                          required
                          type={passwordType}
                          className='form-control'
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder='Enter confirm password'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-5 ' style={{ cursor: 'pointer' }}>
                  <div
                    className=' d-flex align-items-center'
                    style={{ cursor: 'pointer' }}
                  >
                    <input
                      type='checkbox'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        passwordType === 'password'
                          ? setPasswordType('text')
                          : setPasswordType('password');
                      }}
                    />
                    <p className='ps-2'>Show password</p>
                  </div>
                </div>

                <div>
                  <Button type='submit' className='w-100 mt-3 submit-button'>
                    Register
                  </Button>
                </div>

                <div className='mt-3 text-end'>
                  <Link to='/speaker-login' className='text-decoration-none'>
                    Back to login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
