import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import fetcher from '../../Helpers/Fetcher/fetchApi';
import Toaster from '../Toaster/Toaster';

export default function UpdateMaterial({
  handleCloseModal,
  materialId,
  showModal,
  setMaterial,
  materials,
}: any) {
  const [profilePhoto, setProfilePhoto] = useState<any>('');
  const { register, handleSubmit, reset } = useForm<any>();
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(true);
  const [mtId, setMtId] = useState(materialId?.sm_visiblity);
  console.log(mtId);
  useEffect(() => {
    setIsLoading(true);
    setMtId(parseInt(materialId?.sm_visiblity));
    setIsLoading(false);
  }, [materialId?.sm_visiblity]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    const fromData = new FormData();
    if (data.sm_details) {
      fromData.append('sm_details', data.sm_details);
    }
    if (profilePhoto) {
      fromData.append('sm_file', profilePhoto);
    }
    fromData.append('sm_visiblity', data.sm_visiblity);
    const res = await fetcher.patch({
      url: `/api/fair/guest-speaker/update/materials/${materialId?.sm_id}`,
      body: fromData,
    });

    if (res.success) {
      Toast.fire({
        icon: 'success',
        title: `updated successfully`,
      });
      const filterData: any = materials.filter(
        (item: any) => item.sm_id === materialId.sm_id
      );
      const newData: any = {
        sm_details: data.sm_details
          ? data.sm_details
          : filterData[0].sm_details,
        sm_file: res?.sm_file || filterData[0].sm_file,
        sm_id: filterData[0].sm_id,
        sm_visiblity: parseInt(data.sm_visiblity),
      };
      const updateData = materials.map((item: any) =>
        item.sm_id === materialId.sm_id ? newData : item
      );
      setMaterial(updateData);
    } else {
      Toast.fire({
        icon: 'error',
        title: res.message,
      });
    }
    reset();
    setProfilePhoto('');
    handleCloseModal();
  };

  return (
    <>
      {!isLoading && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Update material</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor='' className='pb-2 form-lebel'>
                  Visiblity
                </label>
                <br />
                <select {...register('sm_visiblity')} className='form-select'>
                  <option className='my-2 p-2' value={1}>
                    Published
                  </option>
                  <option className='my-2 p-2' value={0}>
                    Unpublished
                  </option>
                </select>
              </div>
              <div className='my-3'>
                <label htmlFor='' className='pb-2'>
                  Material Details
                </label>
                <br />

                <textarea
                  {...register('sm_details')}
                  className='inputFiled'
                  id=''
                  placeholder='type here'
                  cols={50}
                  rows={5}
                />
              </div>
              <div className='w-150-100'>
                <div className='companies-logo-card'>
                  <div className='img-thumbnail ' style={{ width: '280px' }}>
                    {profilePhoto ? (
                      <img
                        style={{
                          width: '270px',
                          height: '135px',
                        }}
                        className=' ls-is-cached lazyloaded'
                        id='uploadedImage'
                        src={URL.createObjectURL(profilePhoto)}
                        alt=''
                      />
                    ) : (
                      <img
                        style={{
                          width: '270px',
                          height: '135px',
                          objectFit: 'contain',
                        }}
                        className=' ls-is-cached lazyloaded'
                        id='uploadedImage'
                        src='/assets/image.png'
                        alt=''
                      />
                    )}
                  </div>
                  <div className='pt-4'>
                    <label htmlFor='' className='pb-2'>
                      Upload Files
                    </label>
                    <br />
                    <div>
                      <input
                        type='file'
                        id='actual-btn1'
                        accept='image/jpg, image/png, image/jpeg'
                        hidden
                        onChange={(e: any) =>
                          setProfilePhoto(e.target.files[0])
                        }
                      />

                      <label
                        htmlFor='actual-btn1'
                        className='upload-visitor-image-btn text-center'
                      >
                        Upload file
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-text'>
                  For Best View Upload Image size 600 X 600px
                </div>
              </div>

              <Button className='mt-5 d-flex order-2 ms-auto' type='submit'>
                Submit
              </Button>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
