import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../Context/AuthContex/AuthContex";
import DragAndDrop from "./DragAndDrop";
import Toaster from "../Toaster/Toaster";
import Loader from "../Spinner/Loader";
import axios from "axios";
import { BaseUrl } from "../../Helpers/Constant";

const AddProductAndServices = ({
  setAllProducts,
  allProducts,
  setToggle,
  toggle,
}: any) => {
  const Toast = Toaster();
  const [files, setFiles] = useState<any>([]);
  const { user } = useAuthContext();
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    data.user_fair_member_id = user?.user_fair_member_id;
    data.user_fair_member_company_id = user?.user_fair_member_company_id;

    if (files.length > 4) {
      Toast.fire({
        icon: "error",
        title: `please add maximum four images`,
      });
      setLoading(false);
      return;
    }

    const formData = new FormData();

    for (const key in data) {
      if (data[key] !== "") {
        formData.append(key, data[key]);
      }
    }

    console.table(Object.fromEntries(formData));

    files.forEach((img: any, index: number) => {
      const name = "img_" + (index + 1);
      formData.append(name, img);
    });

    try {
      const { data: res } = await axios.post(
        `${BaseUrl}/api/fair/add-product/into-fair-member`,
        formData
      );
      if (res.success) {
        setAllProducts([
          ...allProducts,
          {
            fair_member_product_name: data.fair_member_product_name,
            // fair_member_product_price: data.fair_member_product_price,
            user_fair_member_product_type: data.user_fair_member_product_type,
            user_fair_member_product_status: "pending",
            fair_member_product_id: res.fair_member_product_id,
          },
        ]);
        Toast.fire({
          icon: "success",
          title: `${res.message}`,
        });
        setToggle(!toggle);
        reset();
        setFiles([]);
        setLoading(false);
      } else {
        Toast.fire({
          icon: "error",
          title: `${res.message}`,
        });
      }
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });

      setLoading(false);
    }
  };

  return (
    <div>
      <div className='col-md-12 mb-4 mt-3'>
        <div className='front-card '>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='form-group col-md-12 mb-3'>
                <label>Item Name </label>
                <input
                  type='text'
                  className='form-control '
                  {...register("fair_member_product_name")}
                  placeholder='Enter Item Name'
                  id='productName'
                />
              </div>
              {/* <div className='form-group col-md-6 mb-3'>
								<label className='d-flex justify-content-between'>
									<div className='w-50'>
										<span className='special_price'></span>Item
										<span className='price_range d-none'>Start</span> Price
									</div>
								</label>
								<input
									type='number'
									className='form-control '
									{...register('fair_member_product_price')}
									placeholder='Enter Item price'
									id='price_start'
								/>
							</div> */}

              {/* <div className='form-group col-md-6 mb-3'>
								<label className='special_price'>Special Price</label>
								<div className='d-flex justify-content-between align-items-center'>
									<input
										type='number'
										className='form-control special_price'
										placeholder='Enter special price'
										id='special_price'
										{...register('fair_member_special_product_price')}
									/>
								</div>
							</div> */}

              <div className='form-group col-md-6 mb-3'>
                <label>
                  Item Type <span className='require'>*</span>
                </label>
                <select
                  className='form-select'
                  defaultValue='Product'
                  {...register("user_fair_member_product_type")}
                >
                  <option value='Service'>Service</option>
                  <option value='Product'>Product</option>
                </select>
              </div>

              <div className='form-group col-md-6 mb-3'>
                <label>Item URL </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Product Url'
                  id='product_url'
                  {...register("fair_member_product_url")}
                />
              </div>

              <div className='form-group col-md-12 mb-3'>
                <label>Item Description</label>
                <textarea
                  className='form-control '
                  placeholder='Enter Item Description'
                  id='description'
                  {...register("fair_member_product_description")}
                ></textarea>
                <div className='text-end'>
                  <small id='description_msg'>
                    Must be 10-500 words in length; written:{" "}
                  </small>
                </div>
              </div>
              <div className='form-group mb-0 dropzone-group light-style mb-3'>
                <div
                  className='alert alert-danger alert-dismissible image_upload_errors d-none'
                  role='alert'
                ></div>

                <label>
                  Item Images (Max 4){" "}
                  <small>
                    <span className='request-time'>
                      Photo Must be JPG, JPEG, GIF or PNG and max file size 2MB.
                      For Best View Photo 500 x 250px
                    </span>
                  </small>
                </label>

                <div>
                  <div className='dz-message needsclick text-center'>
                    <DragAndDrop setFiles={setFiles} files={files} />
                  </div>
                </div>
              </div>

              <div className='form-group col-md-12 mb-3 text-end'>
                {loading ? (
                  <Loader css={"btn"} />
                ) : (
                  <button
                    type='submit'
                    style={{
                      backgroundColor: "#1982c3",
                      color: "white",
                    }}
                    className='btn'
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProductAndServices;
