import React, { useContext, useReducer, useEffect, useState } from 'react';
// import fetcher from '../../Helpers/Fetcher/fetchApi';
import {
  AUTH_SPEAKER_USER_SUCCESS,
  AUTH_SPEAKER_USER_FAILED,
  BaseUrl,
} from '../../Helpers/Constant';
import { ISpeakerAuthContext } from '../../Types/VisitorAuthTypes';
import speakerReducer from '../../Reducers/AuthReducer/SpeakerReducer';
import axios from 'axios';

const initialState: ISpeakerAuthContext = {
  speakerUser: {},
};
const SpeakerContext = React.createContext(initialState);
const SpeakerAuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(speakerReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem('speaker_token');
    if (token) {
      (async () => {
        setIsLoading(true);
        try {
          const { data } = await axios.get(
            `${BaseUrl}/api/auth/guest-speaker/profile`,
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          );
          dispatch({ type: AUTH_SPEAKER_USER_SUCCESS, payload: data.data });

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          dispatch({ type: AUTH_SPEAKER_USER_FAILED });
        }
      })();
    }
  }, []);

  return (
    <SpeakerContext.Provider
      value={{ ...state, dispatch, isLoading, setIsLoading }}
    >
      {children}
    </SpeakerContext.Provider>
  );
};

const SpeakerAuthContext = () => {
  return useContext(SpeakerContext);
};

export { SpeakerAuthContextProvider, SpeakerAuthContext };
