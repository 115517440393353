import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/Styles/Header.css';
import '../src/Styles/Button.css';
import '../src/Styles/Login-Reg.css';
import '../src/Styles/Footer.css';
import '../src/Styles/Profile.css';
import '../src/Styles/VisitorProfile.css';
import '../src/Styles/EventList.css';
import { RouterProvider } from 'react-router-dom';
import { routers } from './Utils/Routers';
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
  AiFillYoutube,
  AiOutlineMail,
} from 'react-icons/ai';
import { BsPinterest } from 'react-icons/bs';
function App() {
  return (
    <>
      {
        <div>
          <div className='social-links' style={{ backgroundColor: '#16A34A' }}>
            <span>
              <a href='/'>
                <AiFillFacebook />
              </a>
              <a href='/'>
                <AiFillYoutube />
              </a>
              <a href='/'>
                <AiOutlineMail />
              </a>
              <a href='/'>
                <AiFillInstagram />
              </a>
              <a href='/'>
                <AiFillTwitterCircle />
              </a>
              <a href='/'>
                <BsPinterest />
              </a>
            </span>
          </div>
          <RouterProvider router={routers} />
        </div>
      }
    </>
  );
}

export default App;
