import { useEffect, useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { BiTimeFive } from 'react-icons/bi';
import moment from 'moment';
import { BaseUrl, imgUrl } from '../../Helpers/Constant';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
import SeminarEventSidebar from './SeminarEventSidebar';
import { VisitorAuthContext } from '../../Context/AuthContex/VisitorAuthContext';
import Toaster from '../../Components/Toaster/Toaster';

export default function SeminarEventList() {
  const { id } = useParams();

  const { visitorUser } = VisitorAuthContext();
  // const [allEvents, setAllEvents] = useState<IEvent[]>([]);
  // const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [purchase, setPurchase] = useState<any>([]);

  const seminarItem: any = localStorage.getItem('seminar_item');
  const cartItem = JSON.parse(seminarItem);

  const [cart, setCart] = useState<any>(cartItem ? cartItem : []);
  const [show, setShow] = useState(false);
  const Toast = Toaster();

  useEffect(() => {
    setLoading(true);
    if (visitorUser?.visitor_id) {
      try {
        (async () => {
          const { data } = await axios.get(
            `${BaseUrl}/api/fair/event/get/by-seminar-and-visitor/${id}/${visitorUser?.visitor_id}`
          );

          if (data.success) {
            setPurchase(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })();
      } catch (err: any) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [id, visitorUser?.visitor_id]);

  return (
    <>
      {loading && <Loader />}
      <Layout>
        <hr />
        <Container>
          <div className='section-title breadcrumb-card d-flex flex-mo-column align-items-center  justify-content-between mt-5'>
            <div className='text-start w-50'>
              <h2 className='mb-0 my-3'>Dhaka Travel Mart Events</h2>
            </div>
            {/* <div className='w-50 d-flex flex-mo-column justify-content-end'>
                <div className='filter-parent'>
                  <InputGroup>
                    <InputGroup.Text id='basic-addon1'>
                      <AiOutlineSearch />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder='Search Event'
                      aria-label='Username'
                      onChange={(e) => setSearchValue(e.target.value)}
                      aria-describedby='basic-addon1'
                    />
                  </InputGroup>
                </div>
              </div> */}
          </div>

          <div className='text-end mb-2 position-relative'>
            <AiOutlineShoppingCart
              size={40}
              onClick={() => setShow(true)}
              className='pointer'
            />
            <div className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger'>
              {cart.length ? cart.length : 0}
            </div>
          </div>

          {visitorUser?.visitor_id ? (
            <>
              <Row sx={12} md={4}>
                {purchase?.length ? (
                  purchase?.map((sEvent: any) => {
                    return (
                      <Col
                        key={sEvent?.event_id}
                        xs={12}
                        md={4}
                        lg={3}
                        className='position-relative'
                      >
                        <div
                          className='events-card-bg event_container mb-4 pointer'
                          onClick={() => {
                            if (!sEvent?.joined) {
                              if (!visitorUser?.visitor_id) {
                                Toaster().fire({
                                  icon: 'error',
                                  title: 'Please login as a visitor',
                                });
                              } else {
                                const f = cart.filter(
                                  (s: any) => s.event_id === sEvent?.event_id
                                );

                                if (f[0]?.event_id !== sEvent?.event_id) {
                                  setCart([...cart, { ...sEvent, price: 500 }]);
                                  localStorage.setItem(
                                    'seminar_item',
                                    JSON.stringify([
                                      ...cart,
                                      { ...sEvent, price: 500 },
                                    ])
                                  );
                                  Toaster().fire({
                                    icon: 'success',
                                    title: 'Added in cart',
                                  });
                                } else {
                                  Toast.fire({
                                    icon: 'error',
                                    title: `Already added in cart`,
                                  });
                                }
                              }
                            }
                          }}
                        >
                          <div className='events-img-sec'>
                            {/* <Link
                                  to={`/visitor-event-list/${sEvent?.event_id}`}
                                > */}
                            <div className='event_wrapper'>
                              <div className='event_venue '>
                                <div>
                                  <BiTimeFive color='white' className='mb-1' />
                                  <span>
                                    {moment(
                                      sEvent?.event_start_time,
                                      'h:mma'
                                    ).format('h:mma')}{' '}
                                    {sEvent?.event_end_time && (
                                      <>
                                        - {''}
                                        {moment(
                                          sEvent?.event_end_time,
                                          'h:mma'
                                        ).format('h:mma')}
                                      </>
                                    )}
                                  </span>
                                </div>
                              </div>

                              <img
                                className='event-img'
                                style={{
                                  minHeight: '14rem',
                                  maxHeight: '14rem',
                                  objectFit: 'cover',
                                }}
                                alt=''
                                src={`${imgUrl}asian_tourism_fair/event_files/${sEvent?.event_photo}`}
                              />
                            </div>
                            {/* </Link> */}
                          </div>
                          <div className='text-center'>
                            <h3 className='event_title'>
                              {/* <Link
																		to={`/visitor-event-list/${sEvent?.event_id}`}
																		className='text-decoration-none text-black'
																	> */}
                              {`${sEvent?.event_title?.slice(0, 50)}...`}
                              {/* </Link> */}
                            </h3>
                          </div>
                        </div>
                        <div style={{ position: 'absolute', top: 4, left: 15 }}>
                          {sEvent.joined ? (
                            <p
                              className='px-1 rounded text-white'
                              style={{ background: '#fc097c' }}
                            >
                              Registered
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <h2 className='text-danger text-center mx-auto my-5'>
                    Events not found
                  </h2>
                )}
              </Row>
            </>
          ) : (
            <h2 className='text-danger text-center mx-auto my-5'>
              Please login as Visitor first to see the event
            </h2>
          )}
        </Container>

        <div>
          <SeminarEventSidebar
            show={show}
            handleClose={() => setShow(false)}
            cart={cart}
            setCart={setCart}
            purchase={purchase}
            setPurchase={setPurchase}
          />
        </div>
      </Layout>
    </>
  );
}
